<template>
    <div class="info">
      <top-bar :title="'小事详情'" :left="true"></top-bar>
      <van-tabs>
        <van-tab title="报事详情">
          <van-cell-group>
            <van-cell   >
              <template #title>
                <div class="blue-box"></div>
                <span class="custom-title">报事信息</span>
              </template>
            </van-cell>
            <van-cell title="事务类型" :value="detailData.typeStr" />
            <van-cell title="具体地点" :value="detailData.address" />
            <van-cell title="地点类型" :value="detailData.placeTypeStr" />
            <van-cell title="定位" :value="detailData.lat == null ?'': detailData.lat + ',' + detailData.lng" />
            <van-cell title="上报时间" :value="detailData.createDate" />
            <van-cell title="处理状态" :value="detailData.statusStr" />
            <van-cell title="处理人" :value="detailData.solveUserName" v-show="detailData.status === 30"/>
            <van-cell title="处理时间" :value="detailData.solveTime" v-show="detailData.status === 30"/>
            <van-cell title="处理结果" :value="detailData.solveDes" v-show="detailData.status === 30"/>
            <van-cell :title="detailData.evaluation == null || detailData.evaluation == ''? '评价' : '已评价'" v-show="detailData.status === 30 || detailData.status === 40">
              <van-rate v-model="detailData.evaluation" readonly size="16px"/>
              <span style="margin-left: 5px" v-if="detailData.evaluation == 5">十分满意</span>
              <span style="margin-left: 5px" v-else-if="detailData.evaluation == 4">比较满意</span>
              <span style="margin-left: 5px" v-else-if="detailData.evaluation == 3">一般</span>
              <span style="margin-left: 5px" v-else-if="detailData.evaluation == 2">不满意</span>
              <span style="margin-left: 5px" v-else-if="detailData.evaluation == 1">十分不满意</span>
            </van-cell>
            <van-cell title="问题描述" class="cont">
              <van-field
                v-model="detailData.content"
                disabled
                autosize
                type="textarea"
                placeholder="这是一条问题描述"
                style="text-align: right;  padding:0"
              />
            </van-cell>
          </van-cell-group>
          <van-uploader v-model="fileList" :deletable="false" disabled  :show-upload="false" style="padding:20px"/>
        </van-tab>
        <van-tab title="处理详情" v-if="problemData.status == 0 || problemData.status == 30 || problemData.status == 40">
            <van-cell >
              <template #title>
                <div class="blue-box"></div>
                <span class="custom-title">处理详情</span>
              </template>
            </van-cell>
            <van-field v-model="detailData.statusStr" disabled label="处理状态" placeholder="请输入" input-align="right"/>
            <van-field v-model="detailData.solveUserName" disabled label="处理人" placeholder="请输入" input-align="right"/>
            <van-field v-model="detailData.solveTime" disabled label="处理时间" placeholder="请输入" input-align="right"/>
            <van-field v-model="detailData.solveDes" disabled label="处理结果" placeholder="请输入" input-align="right" rows="4" type="textarea"/>
          <van-uploader v-model="fileList" :deletable="false" disabled  :show-upload="false" style="padding:20px"/>
          <van-cell class="evaluate" v-show="detailData.status == 30">
            <span>评价</span>
            <van-rate v-model="number" color="#000" size="28px" @change="rateChange" :disabled="detailData.evaluation !=null && detailData.evaluation !=''"/>
            <span style="margin-left: 30px" v-show="detailData.evaluation == null || detailData.evaluation == ''">{{val}}</span>
          </van-cell>
          <div v-if="detailData.status == 30 && detailData.evaluation == null || detailData.evaluation == ''">
            <van-row>
              <van-col :span="24" style="padding-top: 80px">
                <van-button type="info" size="large" round @click="release(detailData.id)">发布评价</van-button>
              </van-col>
            </van-row>
          </div>
        </van-tab>
      </van-tabs>

      <!-- 提交按钮 -->
      <div v-if="problemData.status != 0 && problemData.status != 30">
        <van-row v-if="problemData.status == 20">
          <van-col :span="24" style="padding-top: 80px">
            <van-button type="info" size="large" round @click="handling">处理</van-button>
          </van-col>
        </van-row>
<!--        <van-row  v-if="problemData.status == 10">-->
<!--          <van-col :span="24" style="padding-top: 80px">-->
<!--            <van-button type="info" size="large" round @click="change">编辑</van-button>-->
<!--          </van-col>-->
<!--&lt;!&ndash;          <van-col :span="12" style="padding-top: 80px">&ndash;&gt;-->
<!--&lt;!&ndash;            <van-button type="info" size="large" round @click="handling">处理</van-button>&ndash;&gt;-->
<!--&lt;!&ndash;          </van-col>&ndash;&gt;-->
<!--        </van-row>-->
      </div>
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {getImageStream} from '@/utils/index'

import { mapState,mapMutations } from 'vuex'
export default {
  data () {
    return {
      value: '',
      detailData: {},
      fileList:[],
      number: 0,
      val: '',
      valName: ''
    }
  },
  computed:{...mapState(['problemData'])},
  components :{
    topBar
  },
  methods: {
    ...mapMutations(['setProblemHandling']),
    getDetail () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/problem/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.problemData.id
        })
      }).then(({data})=> {
          if (data.code == 0) {
            if (data.entityVO.problemFiles && data.entityVO.problemFiles.length != 0) {
            data.entityVO.problemFiles.forEach(item=> {
              let u;
              if (item.type == 1) {
                u = getImageStream(item.filePath)

                let url = {
                  uid: this.fileList.length,
                  id: item.id,//多图需要记录一个uid，随图片数量增加而增加
                  status: 'done',//uploading上传中，done上传完成，error上传失败
                  fileType: item.fileType,
                  url: u,
                  realUrl: item.filePath.replace(/\\/g, "/")
                }
                this.fileList.push(url);
              }
            })
            }
            this.detailData = data.entityVO
          this.$toast.clear()
          } else {
          this.$toast.clear()
          this.$toast.fail(data.msg);
          }
        })
    },
    rateChange (value) {
      this.number = value
      if (value ===5) {
        this.val = '十分满意'
      } else if (value ===4) {
        this.val = '比较满意'
      } else if (value ===3) {
        this.val = '一般'
      } else if (value ===2) {
        this.val = '不满意'
      } else if (value ===1) {
        this.val = '十分不满意'
      }
    },
    change () {
      this.$router.push('/trifle-change')
    },
    handling () {
      this.$store.commit('setProblemHandling',{})
      this.$router.push('/trifle-handling')
    },
    release (id) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/problem/evaluation'),
        method: 'post',
        data: this.$http.adornData({
          id: id,
          evaluation: this.number,
          userId: this.$globalData.userInfo.userId
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.$toast.success (data.msg);
          this.getDetail(id)
        } else {
          this.$toast.fail(data.msg);
        }
      })
    }
  },
  created () {
    document.querySelector('html').style.backgroundColor = '#fff'
    this.getDetail()
  },
  beforeDestroy () {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
<style>
  a {
  color: rgb(4, 50, 255);
}
.blue-box {
  background-color: #007AFF;
  height: 35px;
  width: 6px;
  border-radius: 6px;
  float: left;
  margin-top: 8px;
  margin-right: 8px;
}
    .van-field__control {
      text-align: right ;
    }
</style>
<style lang="scss" scoped>
.cont {
  .van-cell__value {
    width: 500px;
    flex: 2;
  }
}
.van-uploader__upload {
  display: none !important;
  .van-uploader__input {
    display: none !important;
  }
}
  .van-divider{
    border-color: #000;
  }
  .info {
    margin-top: 100px;
  }
  .evaluate {
    span {
      font-size: 35px;
      font-weight: 600;
    }
    .van-rate {
      margin-left: 50px;
      vertical-align: bottom;
    }
  }
</style>
